<script setup lang="ts">
const props = defineProps<{
  title?: string
}>()

const { t } = useI18n()
const config = useRuntimeConfig()

const authProviders = [
  {
    icon: 'mdi-google',
    url: '/api/auth/google/login',
    text: `${t('Continue with')} Google`,
  },
  // {
  //   icon: 'mdi-apple',
  //   url: '/api/auth/apple/login',
  //   text: `${t('Continue with')} Apple`,
  // },
  // {
  //   icon: 'mdi-facebook',
  //   url: '/api/auth/facebook/login',
  //   text: `${t('Continue with')} Facebook`,
  // },
  {
    icon: 'mdi-twitter',
    url: '/api/auth/twitter/login',
    text: `${t('Continue with')} X / Twitter`,
  },
]

if (config.public.env.isDevelopment) {
  authProviders.push({
    icon: 'mdi-github',
    url: '/api/auth/github/login',
    text: `${t('Continue with')} GitHub`,
  })
}

const route = useRoute()
const toParam = computed(() => {
  const params: { param: string; value: string }[] = []
  if (route.query.to) return `?to=${encodeURIComponent(String(route.query.to))}`
  if (route.query.to)
    params.push({ param: 'to', value: encodeURIComponent(String(route.query.to)).toString() })
  if (params.length) return `?${params.map((e) => `${e.param}=${e.value}`).join('&')}`
  else return ''
})
</script>

<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div class="mb-2">{{ props.title }} :</div>
    <VList style="display: flex; flex-direction: column; background-color: transparent">
      <VBtn
        v-for="link in authProviders"
        :key="link.icon"
        :href="link.url + toParam"
        variant="outlined"
        height="42"
        class="mb-2 login-provider-button"
      >
        <span class="login-provider-button-text">
          <VIcon :icon="link.icon" size="26" class="mr-2" />
          {{ link.text }}</span
        >
      </VBtn>
    </VList>
  </div>
</template>

<style scoped>
.login-provider-button-text {
  text-transform: none;
  display: flex;
  align-items: center;
  color: rgba(var(--v-theme-on-surface), 0.6);
}

.login-provider-button {
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 0.5em;
  border: 1px solid rgba(var(--v-theme-on-surface), 0.6);
}

.login-provider-button:hover {
  border-color: rgba(var(--v-theme-primary), 0.5) !important;
  background-color: rgba(var(--v-theme-primary), 0.8);
}

.login-provider-button:hover .login-provider-button-text {
  color: rgb(var(--v-theme-on-surface));
  border-color: rgba(var(--v-theme-primary), 1);
}
</style>
